import { ProfileEditForm } from '../components/members/ProfileEdit';

export async function makeTcalApiRequest(
  route: string,
  method?: string,
  data?: any
) {
  try {
    const accessToken = localStorage.getItem('access_token');

    let baseOptions = {
      method: method ?? 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'auth-provider': 'auth0'
      }
    };

    let options = {};

    if (data) {
      options = {
        ...baseOptions,
        body: JSON.stringify(data)
      };
    } else {
      options = baseOptions;
    }

    const req = await fetch(
      `${process.env.GATSBY_TCAL_API_URL}/${route}`,
      options
    );

    if (!req.ok) {
      const err = await req.json();
      return err;
    }

    return await req.json();
  } catch (err) {
    return err;
  }
}

export const loginTCAL = async (email: string, password: string) => {
  return await makeTcalApiRequest('auth/login', 'POST', {
    email,
    password
  });
};

export const getAffiliationsForUserTCAL = async () => {
  return await makeTcalApiRequest('organizations/affiliations');
};

export const getGradesTCAL = async () => {
  return await makeTcalApiRequest('grades');
};

export const getPositionsTCAL = async () => {
  return await makeTcalApiRequest('positions');
};

export const getOrganizationsForSelfAffiliationTCAL = async (
  search: string
) => {
  return await makeTcalApiRequest(
    `organizations?isForSelfAffiliation=true&search=${search}`
  );
};

export const createOwnOrganizationTCAL = async (data: any) => {
  return await makeTcalApiRequest(`organizations`, 'POST', data);
};

export const createOwnOrganizationAffiliationTCAL = async (data: any) => {
  return await makeTcalApiRequest('organizations/affiliation', 'POST', data);
};

export const editOwnOrganizationAffiliationTCAL = async (data: any) => {
  return await makeTcalApiRequest('organizations/affiliation', 'PUT', data);
};

export const deleteOwnOrganizationAffiliationTCAL = async (data: any) => {
  return await makeTcalApiRequest('organizations/affiliation', 'DELETE', data);
};

export const getOrganizationTypesTCAL = async () => {
  return await makeTcalApiRequest('organizations/types');
};

export const getDistrictsTCAL = async ({ search }: { search?: string }) => {
  if (search) {
    return await makeTcalApiRequest(`districts?search=${search}`);
  }
  return await makeTcalApiRequest('districts');
};

export const getLegacyEventApplicationsTCAL = async () => {
  return await makeTcalApiRequest('events/portal-events');
};

export const getConferenceDaysEventsTCAL = async () => {
  return await makeTcalApiRequest('events/event-section-events');
};

export const updateEmail = async (email: string) => {
  return await makeTcalApiRequest('users/email', 'POST', {
    email
  });
};

export const updateUser = async (data: ProfileEditForm) => {
  return await makeTcalApiRequest('users/profile', 'PUT', {
    data
  });
};

export const resetPassword = async (email: string) => {
  return await makeTcalApiRequest('auth/reset-password', 'POST', {
    email
  });
};

export const signUp = async (data: any) => {
  return await makeTcalApiRequest('auth/signup', 'POST', {
    data
  });
};
